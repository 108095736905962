import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from "gatsby";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Create React App - что, кому и зачем? Добро ли? или это зло?" mdxType="SEO" />
    <h1>{`Create React App - что, кому и зачем? Добро ли? или это зло?`}</h1>
    <p>{`Зачастую компании в своих вакансиях для фронтенд-разработчиков указывают, что ожидают от кандидатов
умение писать  Webpack, Babel, Grunt, Gulp и другие виды конфигов, настраивать линтер,
тестирование и статическую типизацию  в проектах. Конфиги сильно разнятся, быстро устаревают и новые
разработчики теряют много времени, чтобы погрузится в специфику сборки проекта. Раньше многие
разработчики имели свой стартовый конфиг для быстрого развертывания нового проекта, некоторые
просто клонировали репозиторий с уже настроенным процессом сборки и затем выкидывали всё лишнее из него.
Нередко приходится менять и обновлять тулзы сразу в десятках репозиториях, что отнимает много времени.`}</p>
    <h3>{`CRA в помощь`}</h3>
    <p>{`Create React App (CRA) - это набор инструментов, созданный и поддерживаемый разработчиками Facebook
для начальной настройки окружения, которое позволяет запускать и собирать React приложение. Нужно всего
лишь запустить одну команду и CRA настроит инструменты, необходимые для работы с проектом.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npx create-react-app my-app && cd my-app

# tree output
my-app
├── README.md
├── node_modules
├── package.json
├── .gitignore
├── public
│   ├── favicon.ico
│   ├── index.html
│   ├── logo192.png
│   ├── logo512.png
│   ├── manifest.json
│   └── robots.txt
└── src
    ├── App.css
    ├── App.js
    ├── App.test.js
    ├── index.css
    ├── index.js
    ├── logo.svg
    ├── serviceWorker.js
    └── setupTests.js
`}</code></pre>
    <p>{`Не нужно настраивать ничего самому за редким исключением, все уже сконфигурировано и изолировано
от кода приложения c помощью библиотеки `}<inlineCode parentName="p">{`react-scripts`}</inlineCode>{`. Это позволяет сразу же сфокусироваться
на написании самого приложения. Когда приложение будет готово к релизу,
можно запустить преднастроенную сборку с минификацией бандла.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run build
`}</code></pre>
    <h3>{`Преимущества CRA`}</h3>
    <h4>{`Несколько секунд до первой строчки кода`}</h4>
    <p>{`Огромным плюсом и удобством является получение сконфигурированного приложения за секунды.
Порог входа для начинающих разработчиков серьезно снизился благодаря появлению CRA. Можно
запустить своё первое React приложение без изучения и настройки тулинга.`}</p>
    <h4>{`Поддержкой и развитем CRA занимаются очень высоквалифированные разработчики`}</h4>
    <p>{`Как же часто разработчики встают перед выбором - что сейчас лучше использовать Webpack или Parcel?
TypeScript или Flow? Какую библиотеку использовать для тестов? А как реагировать на появление
нового бандлера, который стремительно набирает популярность?`}</p>
    <p>{`Не имея большого опыта в настройке тулинга, бывает сложно принять верное решение.
Выбор любого из подходящих инструментов может привести к достижению результата, однако,
не всегда без негативных последствий. Тулинг рано или поздно устаревает в пользу нового
подхода к решению той же задачи.`}</p>
    <p>{`Над CRA работало более 1500 разработчиков, многие из которых самой высочайшей квалификации и способны
выбрать наиболее оптимальный тулинг на сегодняшний день, взесив все за и против, сформировав тем
самым некий стандарт. О многих удобствах сборки с применением лучших практик можно забыть
или не подумать при самостоятельной настройке.`}</p>
    <h4>{`Широкая популярность`}</h4>
    <p>{`Сейчас создание React приложения c помощью CRA используется в большинстве случаев и
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/create-a-new-react-app.html#recommended-toolchains"
      }}>{`рекомендуется в документации React`}</a>{`.
Такая широкая популярность CRA позволяет разработчикам быстрее фокусироваться на коде в незнакомом
репозитории, не думая про тулинг. Также, многие хостинг платформы предлагают удобные решения
для деплоя приложения, созданного с помощью CRA.`}</p>
    <h4>{`Бесплатная поддержка`}</h4>
    <p>{`CRA часто обновляется, переходит на новые подходы и следует лучшим практикам, принятых во
сообществе. Чаще всего для обновления тулинга достаточно обновить библиотеку `}<inlineCode parentName="p">{`react-scripts`}</inlineCode>{`,
однако, бывают и критические изменения, требующие дополнительных действий. Перед обновлением
стоит посетить `}<a parentName="p" {...{
        "href": "https://github.com/facebook/create-react-app/blob/master/CHANGELOG.md"
      }}>{`CHANGELOG`}</a>{`
и изучить секции с описанием миграции с одной версии на другую. Это гораздо легче, чем
отслеживать состояния десятков библиотек самостоятельно и поддерживать их совместимость.`}</p>
    <h4>{`Возможность получить весь тулинг под контроль`}</h4>
    <p>{`В очень редких случаях может потребоваться получить полный контроль над тулингом и
избавиться от удобств CRA.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run eject
`}</code></pre>
    <p>{`Эта команда скопирует всю конфигурацию приложения и расположит по соседству с `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`,
заменив `}<inlineCode parentName="p">{`react-scripts`}</inlineCode>{` на его внутренние зависимости. Приложение продолжит работать
также, однако, потеряет возможность пользоваться всеми преимуществами CRA. К тому же могут
возникнуть проблемы с использованием хостинг-платформ, работающих с CRA, так как некоторые
из них не устанавливают `}<inlineCode parentName="p">{`devDependencies`}</inlineCode>{`.`}</p>
    <h4>{`Возможность расширить конфигурацию`}</h4>
    <p>{`CRA укомплектован двумя конфигурациями для сборки, где стандартная конфигурация используется
по умолчанию. Чтобы воспользоваться конфигурацией с применением TypeScript, нужно дополнительно
указать это при создании приложения.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npx create-react-app my-app --template typescript
`}</code></pre>
    <p>{`Помимо этого, можно расширять начальные конфигурации как своими конфигурациями, так и
конфигурациями сообщества.`}</p>
    <h3>{`Недостатки CRA`}</h3>
    <h4>{`Пользовательская конфигурация`}</h4>
    <p>{`В некоторых случаях бывает сложно интегрировать в сборку нужный инструмент и поддерживать
интеграцию рабочей вместе с обновлениями CRA. Получение обновлений и выбор тулинга
зависит от мейнтейнеров и сообщества.`}</p>
    <h4>{`Абстракция от инструментов сборки`}</h4>
    <p>{`С одной стороны, не думать об устройстве инструментов сборок очень удобно, потому что можно
сконцентрироваться на самом приложении. С другой стороны, очень важно понимать как настроен
процесс сборки, чтобы не терять часы, занимаясь дебагом, что особенно свойственно новичкам.`}</p>
    <h4>{`Некоторая раздутость`}</h4>
    <p>{`Если выйти из CRA можно обнаружить ряд зависимостей, которые используются по умолчанию
в `}<inlineCode parentName="p">{`react-scripts`}</inlineCode>{`, которые могут совсем не пригодиться. К примеру, поддержка SASS и LESS
при использовании чистого CSS.`}</p>
    <h3>{`Заключение`}</h3>
    <p>{`Во время выполнения `}<inlineCode parentName="p">{`eject`}</inlineCode>{` CRA просит поделиться причиной выхода для будущего
изучения мейнтейнерами в целях минимизации количества разумных поводов выхода.
Не все плюсы и минусы эквивалентны друг другу. Поддержка и настройка своего тулинга
является главным неудобством, ради которого стоит использовать CRA с его
несущественными недостатками. `}</p>
    <Link to="/" mdxType="Link">Вернуться на главную</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      